import React, { useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify'
import axios from 'axios';
import { BlueButton } from '../config/PublicThemeProvider';
import TalkToUs from './TalkToUs';
import { MenuItem, Select, InputLabel, Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';



const Input = props => <input {...props} />;
const TextArea = props => <textarea rows="10" {...props} />;

const useSelectStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    marginTop: theme.spacing(2),
    '& .MuiSelect-root': {
      top: '-10px',
    },
    '& .MuiSelect-select': {
      top: '-10px',
    },
    '& .MuiSelect-outlined': {
      top: '-10px',
    },
    '& .MuiInputLabel-shrink': {
      top: '-10px',
    },
    '& MuiOutlinedInput-input': {
      top: '-10px',
    },
  },
}));

export default function TalkToUsContainer(props) {
  const selectClasses = useSelectStyles();

  const initialValues = { firstName: '', lastName: '', email: '', company: '', message: '', employees: '', use_bim: 'não', bim: '', plan: props.selectedConsultPlan };
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const company_employees_options = ['0-10','11-20','21-50','51-100','101-1000','mais de 1000'];
  const bim_options = ['iniciantes – começando a fazer os projetos em BIM','intermediários - já foi feito algum piloto em BIM, mas não é amplamente utilizado','plenos - até 50% dos projetos recentes feitos utilizam BIM na empresa','expert – até 80% dos projetos da empresa são feitos em BIM'];
  

  function handleChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (formValues.plan == '' && props.selectedConsultPlan != '') {
      setFormValues({ ...formValues, plan: props.selectedConsultPlan });
    }
  }

  function handleSubmit() {
    setLoading(true);
    
    const obj = { 
      first_name: formValues.firstName, 
      last_name: formValues.lastName,
      email: formValues.email,
      company: formValues.company,
      message: formValues.message,
      employees: formValues.employees,
      bim: formValues.bim,
      use_bim: formValues.use_bim,
      plan: formValues.plan,
    };

    // console.log('obj', obj);
    
    axios.post('/contact', { contact: obj })
      .then((res) => {
        toast.success('Sua mensagem foi enviada!');
        setFormValues(initialValues);
        setErrors({});
      })
      .catch(({ response }) => {
        const { status, data } = response;

        if(status === 422) {
          const { first_name: firstName = '', last_name: lastName = '', email = '', company = '', message = ''} = data;
          setErrors({ firstName, lastName, email, company, message});
        }
      })
      .then((res) => {
        setLoading(false);
      });
  }


  function checkFields(){
    return presentField('firstName') &&  presentField('email') && presentField('company') && presentField('employees') &&
           presentField('message') && ( (formValues.use_bim == 'não') || (formValues.use_bim == 'sim' &&  presentField('bim')) );
  }
  function presentField(field){
    return formValues[field].length > 0
  }  

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <TalkToUs 
          required
            label="Primeiro nome"
            handleChange={handleChange}
            errorMsg={errors.firstName}
            name="firstName"
            value={formValues.firstName}
            InputComponent={Input}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TalkToUs
            label="Sobrenome"
            handleChange={handleChange}
            errorMsg={errors.lastName}
            name="lastName"
            value={formValues.lastName}
            InputComponent={Input}
          />
        </Grid>
        <Grid item xs={12}>
          <TalkToUs
            label="Email"
            handleChange={handleChange}
            errorMsg={errors.email}
            name="email"
            value={formValues.email}
            InputComponent={Input}
          />
        </Grid>
        <Grid item xs={12}>
          <TalkToUs
            label="Empresa"
            handleChange={handleChange}
            errorMsg={errors.company}
            name="company"
            value={formValues.company}
            InputComponent={Input}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
        <FormControl variant="outlined" className={selectClasses.root} style={{minWidth: '100%'}}>
          <InputLabel htmlFor="out-comp" style={{ color: '#00acd0', fontFamily: 'Work Sans, Arial, Sans Serif', fontWeight: 700 }}>Quantos Funcionários?</InputLabel>
          <Select
            
            required
            id="employees" 
            name="employees" 
            value={formValues.employees}
            onChange={handleChange}
            fullWidth
            variant="outlined"
          >
            {company_employees_options.map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
          </Select>            
        </FormControl>

           
        </Grid> 
        <Grid item xs={12} sm={2}>
          <InputLabel htmlFor="uncontrolled-native" style={{ color: '#00acd0', fontFamily: 'Work Sans, Arial, Sans Serif', fontWeight: 700 }} >Usam BIM?</InputLabel>
          <RadioGroup row aria-label="use_bim" name="use_bim" value={formValues.use_bim} onChange={handleChange}>
            <FormControlLabel value="não" control={<Radio />} label="Não" />
            <FormControlLabel value="sim" control={<Radio />} label="Sim" />
          </RadioGroup>                  
        </Grid> 
        <Grid item xs={12} sm={6}>
          {formValues.use_bim == 'sim' && 
            <FormControl variant="outlined" className={selectClasses.root} style={{ minWidth: '100%', maxWidth: '100%' }}>
              <InputLabel htmlFor="out-bim" style={{ color: '#00acd0', fontFamily: 'Work Sans, Arial, Sans Serif', fontWeight: 700 }} >Qual Grau de Maturidade?</InputLabel>
              <Select
                
                required
                id="bim" 
                name="bim" 
                value={formValues.bim}
                onChange={handleChange}
                fullWidth
              >
                {bim_options.map((v) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
              </Select>   
            </FormControl>     
          }   
        </Grid>  


        <Grid item xs={12}>
          <TalkToUs
            label="Sua mensagem"
            handleChange={handleChange}
            errorMsg={errors.message}
            name="message"
            value={formValues.message}
            InputComponent={TextArea}
          />
        </Grid>
        <Grid item xs={12}>
          <BlueButton disabled={!checkFields() || loading} style={{ minWidth: '11.82rem' }} onClick={handleSubmit}>{loading ? 'Enviando...' : 'Enviar mensagem' }</BlueButton>
        </Grid>
      </Grid>
    </div>
  );
}
